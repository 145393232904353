<template>
  <div>
    <portal to="page-top-title">New Feedback</portal>
    <portal to="page-top-right"/>

    <box-form action="create" data-prop="formData" url="/feedbacks" redirect="feedbacks-list" :loaded="loaded" loader>
      <template slot="content">
        <feedback-form-general :formData="formData" :data="data" v-cloak/>
        <component :is="eventLayoutName" :formData="formData" :data="data" v-cloak/>
        <component :is="actionLayoutName" :formData="formData" :data="data" v-cloak/>
      </template>
    </box-form>
  </div>
</template>

<script>
import BoxForm from '@/views/components/data/BoxForm'
import FeedbackFormGeneral from '@/views/pages/feedbacks/partials/forms/General'
import FeedbackFormEventClick from '@/views/pages/feedbacks/partials/forms/EventClick'
import FeedbackFormEventRegistration from '@/views/pages/feedbacks/partials/forms/EventRegistration'
import FeedbackFormActionEmail from '@/views/pages/feedbacks/partials/forms/ActionEmail'
import FeedbackFormActionCallback from '@/views/pages/feedbacks/partials/forms/ActionCallback'
import FeedbackEvents from '@/configs/feedback-events'
import FeedbackActions from '@/configs/feedback-actions'
import PageData from '@/views/pages/feedbacks/partials/forms/PageData'
import Form from '@/modules/utils/Form'

export default {
  name: 'FeedbacksNew',
  metaInfo () {
    return { title: 'Feedback | New' }
  },
  components: {
    BoxForm,
    FeedbackFormGeneral,
    FeedbackFormEventClick,
    FeedbackFormEventRegistration,
    FeedbackFormActionEmail,
    FeedbackFormActionCallback
  },
  data () {
    return {
      loaded: false,
      showCampaigns: false,
      showLists: false,
      eventLayoutName: '',
      actionLayoutName: '',
      layoutEvents: {
        [FeedbackEvents.CLICK]: FeedbackFormEventClick.name,
        [FeedbackEvents.REGISTRATION]: FeedbackFormEventRegistration.name
      },
      layoutActions: {
        [FeedbackActions.EMAIL]: FeedbackFormActionEmail.name,
        [FeedbackActions.CALLBACK]: FeedbackFormActionCallback.name
      },
      formData: {
        name: Form.types.input(),
        event: Form.types.select(),
        action: Form.types.select(),
        callback_url: Form.types.input(),
        delay: Form.types.input(),
        active: Form.types.boolean(true),
        openers_only: Form.types.boolean(false),
        clickers_only: Form.types.boolean(false),
        lists: Form.types.multiSelect(),
        campaigns: Form.types.multiSelect(),
        api: Form.types.select(),
        sender_categories: Form.types.multiSelect(),
        domains: Form.types.multiSelect(),
        offer: Form.types.select(),
        templates: Form.types.multiSelect(),
        templates_category: Form.types.select()
      },
      data: {
        lists: [],
        campaigns: [],
        events: [],
        actions: [],
        apis: [],
        sender_categories: [],
        domains: [],
        offers: [],
        templateCats: [],
        templates: []
      }
    }
  },
  computed: {
    templateCat () { return this.formData.templates_category.value },
    event () { return this.formData.event.value },
    action () { return this.formData.action.value }
  },
  created () {
    this.initDefault()

    PageData.setResult(this.data)
    PageData.loadAll().then()
  },
  watch: {
    templateCat (val) { this.templateCatChanged(val) },
    event (val) { this.eventChanged(val) },
    action (val) { this.actionChanged(val) }
  },
  methods: {
    eventChanged (event) { this.eventLayoutName = this.layoutEvents[event.id] },
    actionChanged (action) { this.actionLayoutName = this.layoutActions[action.id] },
    templateCatChanged (cat) {
      this.data.templates = []
      this.formData.templates = Form.types.multiSelect()

      PageData.loadTemplates(cat.id).then()
    },
    initDefault () {
      this.loaded = true
    }
  }
}
</script>
